/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'drag-n-drop': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.913 8.173a.45.45 0 01-.098.15l-2.16 2.152a.45.45 0 11-.637-.637l1.395-1.395h-6v6l1.395-1.396a.45.45 0 01.637.638l-2.16 2.16a.45.45 0 01-.637 0l-2.123-2.19a.45.45 0 11.638-.637l1.395 1.394v-6h-6l1.417 1.433a.45.45 0 01-.637.637L.185 8.316a.45.45 0 010-.637l2.16-2.153a.45.45 0 01.638.638L1.588 7.55h6v-6L6.155 2.975a.45.45 0 01-.637-.637l2.16-2.16a.45.45 0 01.637 0l2.16 2.167a.45.45 0 01-.637.638L8.45 1.588v6h6l-1.425-1.433a.45.45 0 01.638-.637l2.16 2.16a.45.45 0 01.097.487l-.007.008z" _fill="#B4BAC6"/>'
  }
})
